import React from "react";

// Components
import Socio from "./Socio";

// Styles
import './styles/Socios.css';

//Assets
import LinealVector from "../assets/lineal-vector.png";
import Asociates from "../assets/asociates.png";
import Eliser from '../assets/eliser-circle.png';
import Miguel from '../assets/miguel-circle.png';
import Gaston from '../assets/gaston-circle.png';
import EliserFull from '../assets/eliser.png';
import MiguelFull from '../assets/miguel.png';
import GastonFull from '../assets/gaston.png';
import Asociate from '../assets/avatar.png';

export default function Socios() {
  const socios = [
    {
      firstTitle: "Eliser García Macdonel",
      imageC: Eliser,
      image: EliserFull,
      content: "Maestro en Derecho, reconocido abogado especialista en litigio en materia penal, civil y mercantil. Cuenta con gran experiencia en el manejo de asuntos relacionados con delitos patrimoniales, ramo en donde los últimos diez años ha asesorado a empresas nacionales e internacionales. El licenciado García Macdonel se desempeña con solvencia frente a medios de comunicación, así como destaca en el manejo de crisis. ",
      id: 1,
    },
    {
      firstTitle: "Miguel Uribe Flores",
      imageC: Miguel,
      image: MiguelFull,
      id: 2,
      content: "Es especialista en Materia Laboral y Relaciones Sindicales, cuenta con más de cincuenta años de experiencia en los que ha asesorado a empresas nacionales y extranjeras de las industrias Gasera, Hulera, Azucarera, Automotriz, Cinematográfica, Televisión, Entretenimiento, orestal, Cartonera y Papel, Transporte, Hotelera y Restaurantera; así como el Sector Estatal y Paraestatal.",
    },
    {
      firstTitle: "Gastón Esquivel Santos",
      imageC: Gaston,
      image: GastonFull,
      id: 3,
      content: "Es uno de los abogados más reconocidos en el ámbito de la Propiedad Intelectual y el Entretenimiento, redes sociales y ámbito digital. El licenciado Esquivel cuenta con más de treinta y cinco años de experiencia en los que ha destacado en la rama del Derecho del Entretenimiento, convirtiéndose uno de los abogados de medios más confiable.",
    },
  ];

  const asociate = socios.map((socio) => {
    return <Socio socio={socio} firstTitle={socio.firstTitle} imageC={socio.imageC} image={socio.image} key={socio.id} content={socio.content} />;
  });

  return (
    <section>
      <article>
        <div className="card-title-container">
          <img src={LinealVector} alt="line" />
          <h2>Socios</h2>
        </div>
      </article>
      <article className="asociates">
        <div className="asociates-image">
          <img src={Asociates} alt="socios" />
        </div>
        <div>{asociate}</div>
      </article>
    </section>
  );
}
