import React from 'react';

//Assets
import LogoSoma from '../assets/soma-logo.png';

export default function Footer() {
    return(
        <section style={{
            display:"flex",
            justifyContent:"center",
            backgroundColor: "black"
        }}>
            <img src={LogoSoma} alt="logo soma"/>
        </section>
    )
}