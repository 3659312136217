import React from "react";

//Assets
import Correo from "../assets/Correo.png";
import Pin from "../assets/icon_ubicacion.png";
import Telefono from "../assets/Phonne.png";

export default function Contacto() {
  return (
    <section
      style={{
        boxShadow: "7px 7px 20px rgba(0, 0, 0, 0.25)",
        display: "flex",
        justifyContent: "center",
        padding:"2em 0"
      }}
      className="contacto"
    >
      <article style={{
          marginRight:"2em"
      }}>
        <div style={{
            display: "flex",
            alignItems: "center",
            marginBottom:"1em"
        }}>
          <img style={{
              marginRight: "1em"
          }} src={Correo} alt="correo" />
          <a style={{
              fontSize:"21px",
              color:"black"
          }} href="mailto:contacto@mue.mx">contacto@mue.mx</a>
        </div>
        <div style={{
            display: "flex",
            justifyContent:"center",
            alignItems: "center"
        }}>
          <img style={{
              marginRight: "1em"
          }} src={Pin} alt="ubicacion" />
          <p>
            Torre Capital Reforma Paseo de la Reforma 250, <br className="br"/> Torre B, piso 26.
            Colonia Juarez, CP: 06600, CDMX
          </p>
        </div>
      </article>
      <article style={{
          display: "flex",
          justifyContent:"center",
          alignItems: "center"
      }}>
        <img style={{
            marginRight: "1em"
        }} src={Telefono} alt="telefono" />
        <p>55 5264 3568 <br/> 55 5264 4321 <br/> 55 5264 3497</p> 
      </article>
    </section>
  );
}
