import React from "react";
import Modal from "./Modal";

export default function Socio({
  image,
  imageC,
  name,
  firstTitle,
  content
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        margin: "32px 0",
        marginLeft: "-4em",
      }}
      className="socio"
    >
      <img
        className="mr-lg-3"
        src={imageC}
        alt={name}
        style={{ width: "285px", height: "285px" }}
      />
      <Modal
        buttonLabel={firstTitle}
        className="custom-button"
        content={content}
        image={image}
        firstTitle={firstTitle}
        buttonClassName="socio"
        imgWidth="150px"
        imgHeight="150px"
        padding="0"
      />
    </div>
  );
}
