import React from 'react';

// Styles
import './styles/Hero.css';

export default function Hero() {
    return(
        <section className="hero">
        </section>
    )
} 