import React from "react";

// Styles
import "./styles/BigCard.css";

// Assets
import ourFirm from "../assets/our-firm.png";
import LinealVector from "../assets/lineal-vector.png";

export default function BigCard() {
  return (
    <section className="big-card-container" id="our-firm">
      <article className="big-card__mini">
        <p>
          Con los más altos estándares técnicos y éticos, y por el profundo
          conocimiento de las necesidades de negocio de nuestros clientes.
        </p>
      </article>
      <article className="big-card">
        <div className="card-title-container">
          <img src={LinealVector} alt="line" />
          <h2>Nuestra Firma</h2>
        </div>
        <div className="our-firm-inside-card">
          <img src={ourFirm} alt="nuestra firma" />
          <div>
            <p>
              <strong>MACDONEL, URIBE Y ESQUIVEL ABOGADOS (MUE)</strong>, es el
              resultado de la asociación de tres abogados y sus equipos los
              cuales han sido líderes en sus ramas, convirtiéndose con la
              experiencia de los socios y la de su plantilla de más de 25
              abogados, en uno de los bufetes jurídicos multidisciplinarios más
              reconocidos de México.
            </p>
          </div>
        </div>
        <div className="card-mission">
          <p  id="asociates">
            <strong>La misión de MUE,</strong> es preservar la excelencia en la
            prestación de servicios jurídicos y seguir siendo uno de los líderes
            en el mundo legal, participando en las operaciones más relevantes en
            todas sus áreas de práctica, donde a la fecha asesoramos a empresas
            AAA.
          </p>
        </div>
      </article>
    </section>
  );
}
