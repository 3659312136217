import React from 'react';

//Assets
import Contacto from '../assets/Contacto.png';
import Linkedin from '../assets/Linkedin.png';
import Facebook from '../assets/Facebook.png';
import Twitter from '../assets/Twiter.png';


export default function SocialMedia() {
    return(
        <section className="social-media">
            <img src={Contacto} alt="Contacto"/>
            <article style={{
                display:"flex",
                justifyContent:"space-around",
                padding: "2em 0"
            }}>
                <a href=""><img src={Linkedin} alt="Linkedin"/></a>
                <a href=""><img src={Facebook} alt="Facebook"/></a>
                <a href=""><img src={Twitter} alt="Twitter"/></a>
            </article>
        </section>
    )
}