import React from "react";

//Components
import SectionTitle from "../components/SectionTitle";

//Assets
import LogoMUE from "../assets/LogoMUEBack.png";

export default function Asesoria() {
  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="asesoria"
    >
      <img
        style={{
          marginBottom: "2em",
        }}
        src={LogoMUE}
        alt="logo MUE"
      />
      <SectionTitle
        sectionTitleFirst="¿Cómo"
        sectionTitleSecond="podemos asesorarte?"
      />
    </section>
  );
}
