import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalExample = (props) => {
  const {
    buttonLabel,
    className,
    content,
    image,
    firstTitle,
    secondTitle,
    buttonClassName,
    imgWidth,
    imgHeight,
    padding
  } = props;

  ModalExample.defaultProps = {
    padding: "1em"
  }

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button className={buttonClassName} style={{ outline: "none" }} onClick={toggle}>
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle} style={{ backgroundColor: "#1D2D4E" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="card-title"
          >
            <img
              style={{ marginRight: "2em", backgroundColor: "#fff", padding: padding, width: imgWidth, height: imgHeight, objectFit: "cover", objectPosition: "top" }}
              src={image}
              alt={firstTitle + " " + secondTitle}
            />
            <p style={{ fontSize: "38px", color: "#fff" }}>
              {firstTitle} <br />{" "}
              {secondTitle}
            </p>
          </div>
        </ModalHeader>
        <ModalBody>{content}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalExample;
