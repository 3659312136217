import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";

import logoMUE from "../assets/LogoMUEBack.png";

const Example = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div style={{position:"fixed", width:"100%", boxShadow: "7px 7px 12px rgba(0, 0, 0, 0.25)", zIndex:"999", top: "0"}}>
      <Navbar color="light" light expand="md">
        <NavbarBrand href="/">
          <img  style={{width:"150px"}} src={logoMUE} alt="logo MUE" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar style={{justifyContent:"flex-end"}}>
          <Nav navbar>
            <NavItem>
              <NavLink href="#our-firm">Nuestra Firma</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#asociates">
                Socios
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#practice-areas">
                Áreas de Práctica
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Example;
