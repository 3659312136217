import React from "react";

import Modal from "./Modal";

import "./styles/Card.css";

export default function Card({
  image,
  firstTitle,
  secondTitle,
  description,
  content,
}) {
  return (
    <article
      style={{
        width: "460px",
        boxShadow: "7px 7px 20px rgba(0, 0, 0, 0.25)",
        borderRadius: "20px",
        padding: "2em",
        margin: "2em 0",
        display:"flex",
        flexDirection: "column",
        justifyContent:"space-around"
      }}
      className="card"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="card-title"
      >
        <img
          style={{ marginRight: "2em" }}
          src={image}
          alt={firstTitle + " " + secondTitle}
        />
        <p style={{ fontSize: "38px", color:"#344260"  }}>
          {firstTitle} <br />{" "}
          <span style={{ color: "#841522" }}>{secondTitle}</span>
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h5
          style={{
            fontSize: "19px",
            textAlign: "center",
            color: "#6A6A6A",
            marginBottom: "2em",
          }}
        >
          {description}
        </h5>
        <Modal
          buttonLabel="conoce más"
          className="custom-button"
          content={content}
          image={image}
          firstTitle={firstTitle}
          secondTitle={secondTitle}
          buttonClassName="button"
        />
      </div>
    </article>
  );
}
