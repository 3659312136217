import React from "react";

export default function SectionTitle({
  sectionTitleFirst,
  sectionTitleSecond,
}) {
  return (
    <section
      style={{
        width: "600px",
        height: "440px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#1D2D4E",
        paddingLeft: "3em",
      }}
      className="section-title"
    >
      <h2
        style={{
          borderBottom: "solid 5px #7C1420",
          color: "white",
        }} 
      >
        {sectionTitleFirst} <br className="br"/>{" "}
        {sectionTitleSecond}
      </h2> 
    </section>
  );
}
